import React, { useCallback } from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {
  laptopContainerStyle,
  mobileContainerStyle
} from '../../components/Common'
import { H1M, P } from '../../styleElements'
import ProductCard from './ProductCard'
import useProductsPage from '../../queries/useProductsPage'
import mq from '../../utils/mediaQuery'
import productList from '../../constants/productList'
import PageTitle from './PageTitle'
import OtherProductCard from './OtherProductCard'

const PAGE_TITLE = 'OUR <br />PRODUCTS'

const ProductsPage = () => {
  const nodes = useProductsPage()
  const filterProductList = productNames => {
    return productNames.map(productName =>
      productList.find(p => p.title.toLowerCase() === productName.toLowerCase())
    )
  }
  const formatProductList = useCallback(
    p => {
      const banner = nodes[p.banner]
      const icon = nodes[p.icon]
      const to = p.to.map(t => ({ ...t, linkImage: nodes[t.linkImage] }))
      const productPageOtherAppsUrl = p.productPageOtherAppsUrl
      return { ...p, banner, icon, to, productPageOtherAppsUrl }
    },
    [nodes]
  )
  const mainProductsList = [
    'PicCollage',
    'OnBeat',
    'Beam',
    'Noodle',
    'Become',
    'MemeMe',
    'Convert',
    'SnapJoy',
    'Sidebar',
    'Chatly'
  ]
  const mainProducts = filterProductList(mainProductsList).map(
    formatProductList
  )
  const otherProductsList = [
    'Cardee',
    'Cattitude',
    'Enchant',
    'FastCut Ai',
    'Groupful',
    'HiLite',
    'Motion',
    'PicCut',
    'PicStory',
    'QuickMo',
    'Salut',
    'Sparko',
    'Stickerize',
    'VDO',
    'Vibes',
    'Visage',
    'Widget Go',
    'WOWSHI'
  ]
  const otherProducts = filterProductList(otherProductsList).map(
    formatProductList
  )

  return (
    <Layout>
      <SEO title="Products" />
      <BannerArea>
        <BannerContainer>
          <Mobile>
            <Img
              fluid={nodes.productsBannerMobile}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </Mobile>
          <Laptop>
            <Img
              fluid={nodes.productsBanner}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </Laptop>
        </BannerContainer>
      </BannerArea>
      <InfoArea>
        <MobileTitleContainer>
          <H1M dangerouslySetInnerHTML={{ __html: PAGE_TITLE }} />
        </MobileTitleContainer>
        <DescriptionContainer>
          <LaptopTitleContainer>
            <H1M dangerouslySetInnerHTML={{ __html: PAGE_TITLE }} />
          </LaptopTitleContainer>
          <DescriptionText>
            We help people create, connect and celebrate in ways that make the
            world more fun and creative. We do this by combining{' '}
            <b>friendly design</b> (such as intuitive interfaces and artistic
            content) with <b>serious technology</b> (such as machine learning
            and computer vision).
            <br />
            <br />
            We are working on exciting new products and features as we explore
            innovative ways to help people get inspired, be creative, share
            memories and more!
          </DescriptionText>
        </DescriptionContainer>
      </InfoArea>
      <CardsContainer>
        {mainProducts.map(card => (
          <ProductCard key={card.title} showBorder {...card} />
        ))}
      </CardsContainer>
      <PageTitleWrapper>
        <PageTitle copy="Other Apps" />
      </PageTitleWrapper>
      <OtherCardsContainer>
        {otherProducts.map(card => (
          <OtherProductCard
            key={card.title}
            icon={card.icon}
            title={card.title}
            link={card.productPageOtherAppsUrl}
          />
        ))}
      </OtherCardsContainer>
    </Layout>
  )
}

const Mobile = styled.div`
  display: block;

  ${mq.md} {
    display: none;
  }
`

const Laptop = styled.div`
  display: none;

  ${mq.md} {
    display: block;
  }
`

const BannerArea = styled.div`
  width: 100vw;
  background-color: ${props => props.theme.color.white};
`

const BannerContainer = styled.div`
  width: 100%;

  ${mq.md} {
    ${laptopContainerStyle}
    max-width: 1440px;
    position: relative;
  }
`

const InfoArea = styled.section`
  ${mobileContainerStyle}
  background-color: ${props => props.theme.color.gray4};
  padding-top: 40px;
  padding-bottom: 40px;
`

const PageTitleWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 27px;
`

const MobileTitleContainer = styled.header`
  margin-bottom: 20px;

  ${mq.md} {
    display: none;
  }
`

const LaptopTitleContainer = styled.header`
  display: none;

  ${mq.md} {
    display: block;
    position: absolute;
    transform: rotate(90deg);
    transform-origin: top left;
    left: 1rem;
    bottom: 67%;
  }
`

const DescriptionContainer = styled.div`
  ${mq.md} {
    max-width: 780px;
    margin: 0 auto;
    width: 80%;
    position: relative;
  }
`

const DescriptionText = styled(P)`
  font-weight: ${props => props.theme.fontWeight.bold};
  ${mq.md} {
    margin-left: 4rem;
  }
`

const CardsContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  padding: 0 20px;

  ${mq.md} {
    padding: 0 32px;
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const OtherCardsContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 0 20px;
  margin-bottom: 70px;

  ${mq.sm} {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  ${mq.md} {
    padding: 0 32px;
    gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 83px;
  }
`

export default ProductsPage
