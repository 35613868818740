import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import mq from '../../utils/mediaQuery'

const OtherProductCard = ({ icon, title, link }) => {
  const handleIconClick = useCallback(() => {
    window.open(link, '__blank')
  }, [link])

  return (
    <Root onClick={handleIconClick}>
      <IconImage fluid={icon} objectFit="cover" objectPosition="50% 50%" />
      <Title>{title}</Title>
    </Root>
  )
}

OtherProductCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string
}

const Root = styled.div`
  padding: 19px 0 14px;
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  cursor: pointer;
  background: white;
  border-radius: 10px;

  ${mq.md} {
    padding: 28px 0 21px;
  }
`

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 9px 0 0;

  ${mq.md} {
    margin: 16px 0 0;
  }
`

const IconImage = styled(Img)`
  width: 54px;
  height: 54px;
  border-radius: 18px;

  ${mq.md} {
    width: 83px;
    height: 83px;
  }
`

export default OtherProductCard
